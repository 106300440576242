<template>
  <v-card>
    <v-card-text class="pa-0 pt-2 pl-4" color="black" style="font-weight: 600"
      >點數紀錄</v-card-text
    >
    <v-card
      v-for="item in availableList"
      :key="item.id"
      flat
      class="pl-4 pt-2 pb-1"
      ><v-card-text
        class="pa-0 ma-0"
        style="font-weight: 600; color: #2A2A2A; font-size: 16px"
        >{{ item.surplus }}/{{ item.amount }}</v-card-text
      ><v-card-text class="pa-0 ma-0"
        >使用期間：{{ item.date.substr(0, 10) }} ~
        {{ item.expireDate.substr(0, 10) }}</v-card-text
      ></v-card
    >
    <v-card-text class="pa-2 pl-4"
      >請於期間內將點數使用完畢</v-card-text
    ></v-card
  >
</template>

<script>
export default {
  props: {
    availableList: {
      type: Array,
    },
  },
};
</script>
