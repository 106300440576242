<template>
  <v-card id="chartCard" flat style="height: 100%">
    <canvas id="myChart" :width="width" :height="height"></canvas
    ><v-overlay
      absolute
      :value="overlay"
      color="white"
      opacity="0.4"
      :dark="false"
    >
      <span class="pa-4" style="font-weight: 700; font-size:25px"
        >暫無數據
        <!-- No data available -->
      </span>
    </v-overlay>
  </v-card>
</template>
<script>
import Chart from 'chart.js';
//  class="small pt-1"
export default {
  props: {
    chartLabels: {
      type: Object,
    },
    chartData: {
      type: Object,
    },
    overlay: {
      type: Boolean,
    },
    chartCardHeight: {
      type: Number,
    }
  },
  components: {},
  data() {
    return {
      myChart: {},
      height: '360',
      width: '800',
      backgroundColor: 'rgba(0,106,216,0.45)',
      borderColor: 'rgba(0,106,216,1)',
    };
  },
  // created() {
  //   window.addEventListener('resize', this.getSize);
  // },
  // destroyed() {
  //   window.removeEventListener('resize', this.getSize);
  // },
  watch: {
    chartLabels: {
      handler() {
        this.showChart();
      },
      deep: true,
    },
    chartData: {
      handler() {
        this.showChart();
      },
      deep: true,
    },
  },
  mounted() {
    this.getSize();
  },
  methods: {
    showChart() {
      var ctx = document.getElementById('myChart');
      if (window.myCharts != undefined) window.myCharts.destroy();
      window.myCharts = new Chart(ctx, {
        type: 'bar',
        data: {
          labels: this.chartLabels.labelText,
          datasets: [
            {
              label: '使用量',
              data: this.chartData.usageData,
              backgroundColor: 'rgba(0,106,216,0.45)',
              borderColor: 'rgba(0,106,216,1)',
            },
            {
              label: '加值',
              data: this.chartData.addData,
              backgroundColor: 'rgba(76,175,80,0.45)',
              borderColor: 'rgba(76,175,80,1)',
            },
            {
              label: '退款',
              data: this.chartData.refundData,
              backgroundColor: 'rgba(126,126,126,0.45)',
              borderColor: 'rgba(126,126,126,1)',
            },
          ],
        },
        options: {
          scales: {
            xAxes: [
              {
                scaleLabel: {
                  display: true,
                  labelString: this.chartLabels.xAxesStr,
                },
                stacked: true,
              },
            ],
            yAxes: [
              {
                ticks: {
                  beginAtZero: true,
                  suggestedMin: -20,
                  suggestedMax: 20,
                },
                stacked: true,
              },
            ],
          },
        },
      });
    },
    getSize(height, width) {
      if (height !== undefined && width !== undefined) {
        this.height = String(height);
        if(this.chartCardHeight !== undefined && this.chartCardHeight !== null){
          this.height = String(this.chartCardHeight - 48)
        }
        if (height < 450) {
          this.width = String(height * 1.7);
        } else {
          this.width = String(width);
        }
      }
      this.showChart();
    },
  },
};
</script>
<style></style>
