<template>
  <div>
    <v-card>
      <v-card-title>退款</v-card-title>
      <v-card-text>{{
        '確定要退款規格品' + ' ' + dataItem.modelName + ' 嗎?'
      }}</v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn outlined @click="refundDialogClose()">{{
          $t('form.actions.cancel')
        }}</v-btn>
        <v-btn
          dark
          color="error"
          :loading="refundloading"
          @click="refundConfirm()"
          >確定</v-btn
        >
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
export default {
  props: {
    dataItem: Object,
    refundloading: Boolean,
  },
  methods: {
    refundDialogClose() {
      this.$emit('refundDialogClose');
    },
    refundConfirm() {
      this.$emit('refundConfirm');
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../styles.scss';
</style>
