<template>
  <td style="text-align: center">
    <v-tooltip bottom v-if="isSystemAdmin">
      <template v-slot:activator="{ on }">
        <v-btn
          v-on="on"
          :id="'btn_' + dataItem.id"
          color="primary"
          icon
          class="mr-2"
          @click="downloadProcessing()"
          ><v-icon>mdi-cloud-download</v-icon></v-btn
        >
      </template>

      <span>下載處理包</span>
    </v-tooltip>

    <v-tooltip bottom v-if="isSystemAdmin">
      <template v-slot:activator="{ on }">
        <v-btn
          v-on="on"
          :id="'btn_' + dataItem.id"
          color="primary"
          icon
          class="mr-2"
          @click="downloadFailedReport()"
          ><v-icon>mdi-alert</v-icon></v-btn
        >
      </template>

      <span>下載報告</span>
    </v-tooltip>

    <v-tooltip bottom v-if="isSystemAdmin">
      <template v-slot:activator="{ on }">
        <v-btn
          v-on="on"
          :id="'btn_' + dataItem.id"
          color="error"
          icon
          class="mr-2"
          :disabled="isRefund"
          @click="refundDialogOpen()"
          ><v-icon>mdi-cash-refund</v-icon></v-btn
        >
      </template>

      <span>退款</span>
    </v-tooltip>

    <v-tooltip bottom v-if="dataItem.modelName">
      <template v-slot:activator="{ on }">
        <v-btn
          v-on="on"
          :id="'btn_' + dataItem.id"
          color="primary"
          icon
          class="mr-2"
          @click="openForge()"
          :disabled="dataItem.status == 5 ? false : true"
          ><v-icon>mdi-eye</v-icon></v-btn
        >
      </template>

      <span>檢視內容</span>
    </v-tooltip>
    <v-tooltip bottom v-if="dataItem.modelName">
      <template v-slot:activator="{ on }">
        <v-btn
          v-on="on"
          :id="'btn_' + dataItem.id"
          color="primary"
          icon
          class="mr-2"
          @click="routeToGroup()"
          ><v-icon>mdi-folder-open</v-icon></v-btn
        >
      </template>

      <span>前往列表</span>
    </v-tooltip>
  </td>
</template>

<script>
import { mapState } from 'vuex';
import ApiConfig from '../../plugins/ApiConfig.js';
export default {
  props: {
    field: String,
    dataItem: Object,
    format: String,
    className: String,
    columnIndex: Number,
    columnsCount: Number,
    rowType: String,
    level: Number,
    expanded: Boolean,
    editor: String,
  },
  computed: {
    ...mapState('auth', ['userInfo']),
    systemRoles() {
      return this.userInfo && this.userInfo.systemRole
        ? this.userInfo.systemRole
        : [];
    },
    isSystemAdmin() {
      var systemAdmin = false;
      for (let i = 0; i < this.systemRoles.length; i++) {
        if (this.systemRoles[i].name == 'System.Admin') {
          systemAdmin = true;
          break;
        }
      }
      return systemAdmin;
    },
    isRefund() {
      if (this.dataItem.modelId == null && this.dataItem.billId == null) {
        return true;
      } else {
        return false;
      }
    },
  },
  data() {
    return {
      downloadType: '',
    };
  },
  methods: {
    openForge() {
      this.$emit('openForge', { dataItem: this.dataItem });
    },
    routeToGroup() {
      this.$emit('routeToGroup', { dataItem: this.dataItem });
    },
    stateCheck() {
      if (this.dataItem.state == '已完成' || this.dataItem.state == '已處理') {
        return false;
      } else {
        return true;
      }
    },
    downloadProcessing() {
      var processingId = this.dataItem.processingId;
      return new Promise((resolve) => {
        this.$API.api.pc.productProcessing
          .get(processingId, ApiConfig.getJsonConfig)
          .then((res) => {
            console.log('res', res);
            let a = document.createElement('a');
            a.setAttribute('href', res.data);
            a.setAttribute('download', this.dataItem.id);
            a.click();
          })
          .catch((error) => {
            console.error(error);
            this.$store.commit('notification/SET_DIALOG', {
              title: 'Error',
              message: error,
              value: true,
            });
          })
          .finally(() => {
            resolve();
          });
      });
    },
    downloadFailedReport() {
      var companyId = this.$route.params.companyId;
      var processingId = this.dataItem.processingId;

      return new Promise((resolve) => {
        this.$API.api.pc.companyProductProcessing
          .get(companyId, processingId, ApiConfig.getJsonConfig)
          .then((res) => {
            let blob = new Blob([res.data], {
              type: res.headers['content-type'],
            });
            let report = window.URL.createObjectURL(blob);

            let a = document.createElement('a');
            a.setAttribute('href', report);
            a.setAttribute('download', this.dataItem.id);
            a.click();
          })
          .catch((error) => {
            console.error(error);
            this.$store.commit('notification/SET_DIALOG', {
              title: 'Error',
              message: error,
              value: true,
            });
          })
          .finally(() => {
            resolve();
          });
      });
    },
    refundDialogOpen() {
      this.$emit('refundDialogOpen', { dataItem: this.dataItem });
    },
  },
};
</script>

<style></style>
