<template>
  <sb-ui-fullScreen
    ref="full-screen"
    :z-index="200"
    height="100vh"
    @toggle="onToggle"
  >
    <v-card
      :class="isFullScreen ? '' : 'ma-3'"
      style="height:calc(100vh - 416px);"
    >
      <!-- >style="height: calc(100% - 194px);" -->
      <v-container fluid class="pa-0 ma-0">
        <v-row class="pa-0 ma-0">
          <v-col class="pa-0 ma-0">
            <Tabs ref="tabs" v-if="!isFullScreen" @tabClick="tabClick" />
            <v-card flat v-if="isFullScreen"
              ><v-card-title class="pa-0 pl-3 pt-1"
                >點數明細</v-card-title
              ></v-card
            >
          </v-col>
          <v-card width="55" flat class="pa-0 ma-0">
            <v-container class="pa-0 ma-0"
              ><v-row class="pa-0 ma-0" align="center" justify="center">
                <!-- <v-divider vertical class="pa-0 ma-0" /> -->
                <v-tooltip bottom>
                  <template #activator="{on}">
                    <v-btn
                      class=" mt-1"
                      v-on="on"
                      icon
                      @click="toggleFullscreen"
                      :disabled="mode == 'details' ? false : true"
                    >
                      <v-icon v-if="!isFullScreen">mdi-fullscreen</v-icon>
                      <v-icon v-else>mdi-fullscreen-exit</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ isFullScreen ? '退出全螢幕' : '全螢幕' }}</span>
                </v-tooltip></v-row
              ></v-container
            ></v-card
          ></v-row
        ></v-container
      >
      <v-card
        flat
        id="chartCard"
        v-if="mode == 'chart'"
        style="height: calc(100% - 48px);"
        ><v-divider /><ChartMode
          ref="chartMode"
          :companyId="companyId"
          :startDate="startDate"
          :timeSelected="timeSelected"
          :endDate="endDate"
          :saveChart="saveChart"
          @saveChartState="saveChartState"
          @saveChartClear="saveChartClear"
          @clearDate="clearDate"
      /></v-card>
      <!-- <v-card flat v-if="mode == 'record'" style="height: calc(100% - 48px);"
      ><v-divider /><RecordMode
    /></v-card> -->
      <v-card flat v-if="mode == 'details'" style="height: calc(100% - 48px);"
        ><v-divider /><DetailsMode
          ref="detailsMode"
          :startDate="startDate"
          :endDate="endDate"
          :isFullScreen="isFullScreen"
          :timeSelected="timeSelected"
          :companyId="companyId"
          :saveGrid="saveGrid"
          @saveGridState="saveGridState"
      /></v-card>
    </v-card>
  </sb-ui-fullScreen>
</template>

<script>
import Tabs from './Tabs';
import ChartMode from './ChartMode';
// import RecordMode from "./RecordMode";
import DetailsMode from './DetailsMode';
export default {
  components: {
    Tabs,
    ChartMode,
    // RecordMode,
    DetailsMode,
  },
  props: {
    companyId: {
      type: String,
    },
    timeSelected: {
      type: Boolean,
    },
    startDate: {
      type: String,
    },
    endDate: {
      type: String,
    },
  },
  data() {
    return {
      mode: 'chart',
      isFullScreen: false,
      saveChart: null,
      saveGrid: null,
    };
  },
  watch: {
    isFullScreen: {
      handler(val) {
        if (!val && this.mode == 'details') {
          setTimeout(() => this.$refs.tabs.fullScreenClose(), 10);
        }
      },
      deep: true,
    },
  },
  created() {
    window.addEventListener('resize', this.getSize);
  },
  destroyed() {
    window.removeEventListener('resize', this.getSize);
  },
  mounted() {
    this.getSize();
  },
  methods: {
    getSize() {
      var height = document.getElementById('chartCard').clientHeight - 90;
      var width = document.getElementById('chartCard').clientWidth - 200;
      this.$refs.chartMode.getSize(height, width);
    },
    tabClick(e) {
      switch (e) {
        case 1: //圖表
          this.$refs.detailsMode.saveGridState();
          this.renderChart();
          break;
        case 2: //點數明細
          this.$refs.chartMode.saveChartState();
          this.renderDetails();
          break;
      }
    },
    renderChart() {
      this.mode = 'chart';
      // this.$refs.chartMode.loadData();
    },
    // renderRecord() {
    //   this.mode = "record";
    // },
    renderDetails() {
      this.mode = 'details';
    },
    saveChartState(type, chartLabels, chartData, checkTime) {
      // console.log(labelText, usageData, addData, refundData, xAxesStr);
      this.saveChart = {
        type: type,
        chartLabels: chartLabels,
        chartData: chartData,
        checkTime: checkTime,
      };
    },
    saveGridState(startDate, endDate) {
      this.saveGrid = {
        startDate: startDate,
        endDate: endDate,
      };
    },
    saveChartClear() {
      this.saveChart = null;
    },
    saveGridClear() {
      this.saveGrid = null;
    },
    clearDate() {
      this.$emit('clearDate');
    },
    onToggle(value) {
      this.isFullScreen = value;
    },
    toggleFullscreen() {
      this.$refs['full-screen'].toggle();
    },
  },
};
</script>
