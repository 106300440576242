<template>
  <v-card outlined style="height:85vh;"
    ><UploadQuota :companyId="companyId"
  /></v-card>
</template>

<script>
import { mapState } from 'vuex';
import UploadQuota from '../../components/UploadQuota/Main';
export default {
  components: {
    UploadQuota,
  },
  // data() {
  //   return {
  //     companyId: this.$route.params.companyId,
  //   };
  // },
  computed: {
    ...mapState('company', {
      companyId: (state) => state.company.id,
    }),
  },
  watch: {
    companyId: {
      handler(val) {
        if (val === undefined || val === null || val === '') {
          this.companyId = this.$route.params.companyId;
        }
      },
      deep: true,
    },
  },
};
</script>
