<template>
  <v-card class="ma-3" style="height: 156px" :loading="loading">
    <v-container fluid
      ><v-row class="mt-0 mb-0"
        ><v-card flat min-width="200px" class="mr-2"
          ><v-card-text class="stringText">剩餘額度</v-card-text>
          <v-skeleton-loader
            v-if="loading"
            width="110"
            height="50"
            type="card"
            class="mt-3 ml-6 mb-3"
            style="margin-bottom: 2px"/>
          <v-card-title
            v-if="!loading"
            class="numberText"
            style="color: #848484;"
            >{{ balance }}</v-card-title
          >
          <v-card-text class="pa-0 pl-6"
            >有效期限：{{ exp }}
            <v-menu offset-y right>
              <template v-slot:activator="{ on }">
                <v-icon v-on="on" small style="margin-top: -3px" color="#959595"
                  >mdi-alert-circle</v-icon
                >
              </template>
              <v-card width="280"
                ><ExpDetailCard :availableList="availableList"
              /></v-card>
            </v-menu> </v-card-text
        ></v-card>

        <v-divider vertical />
        <v-card flat min-width="180px"
          ><v-card-text class="stringText">總使用量</v-card-text>
          <v-skeleton-loader
            v-if="loading"
            width="110"
            height="50"
            type="card"
            class="mt-3 ml-6 mb-3"
            style="margin-bottom: 2px"
          />
          <v-card-title
            v-if="!loading"
            class="numberText"
            style="color: #006ad8;"
            >{{ totalUsed }}</v-card-title
          > </v-card
        ><v-col class="pb-0 pr-4 pt-0" align="end"
          ><span style="color:#5E5E5E"
            >最後更新時間：{{ lastUpdate }}</span
          ></v-col
        ></v-row
      ></v-container
    >
  </v-card>
</template>

<script>
import ApiConfig from '../../plugins/ApiConfig.js';
import ExpDetailCard from './ExpDetailCard';
export default {
  components: {
    ExpDetailCard,
  },
  props: {
    balance: {
      type: Number,
    },
    totalUsed: {
      type: Number,
    },
    companyId: {
      type: String,
    },
  },
  data() {
    return {
      loading: true,
      exp: null,
      availableList: [],
      lastUpdate:
        new Date().toISOString().substr(0, 10) +
        ' ' +
        new Date().toTimeString().substr(0, 5),
    };
  },
  watch: {
    balance: {
      handler(val) {
        if (val !== null) {
          this.loading = false;
        }
      },
      deep: true,
    },
    companyId: {
      handler() {
        if (this.balance !== null) {
          this.loading = false;
        }
        this.loadAvailableList();
      },
      deep: true,
    },
  },
  mounted() {
    if (this.balance !== null) {
      this.loading = false;
    }
    this.loadAvailableList();
  },
  methods: {
    async loadAvailableList() {
      var isEmptyExcluded = true;
      return new Promise((resolve) => {
        this.$API.api.pc.companyProcessingUsage
          .getAvailableList(
            this.companyId,
            isEmptyExcluded,
            ApiConfig.getJsonConfig
          )
          .then((res) => {
            this.availableList = res.data;
            // console.log('this.availableList', this.availableList);
            this.exp = this.availableList[
              this.availableList.length - 1
            ].expireDate.substr(0, 10);
          })
          .catch((error) => {
            console.error(error);
            this.availableList = [];
            if (error.response.status === 403) {
              this.$emit('forbiddenOpen');
            }
          })
          .finally(() => {
            this.loading = false;
            resolve();
          });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../styles.scss';
.numberText {
  font-size: 60px;
  letter-spacing: 5px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  margin-left: 12px;
  margin-bottom: 12px;
}
.stringText {
  font-size: 16px;
  margin-left: 22px;
  padding: 6px;
}
</style>
