<template>
  <v-card flat style=" height: 84.7vh;">
    <v-card-title v-if="!forbidden" style=" height: 68px;">
      <v-icon class="pr-3"> mdi-currency-usd</v-icon>模型處理額度
      <v-spacer />
      <v-card v-if="timeSearch" flat color="transparent"
        ><TimeSelect @timeSelect="timeSelect"
      /></v-card>
      <!-- 按鈕：顯示選擇時間區間 或 查詢+清除 -->
      <v-btn
        v-if="!timeSearch"
        color="primary"
        class="pl-5"
        @click="timeSearch = true"
        ><v-icon left>mdi-calendar-clock</v-icon>選擇時間區間</v-btn
      >
      <v-btn v-if="timeSearch" icon small @click="timeSearchClear()"
        ><v-icon>mdi-close</v-icon></v-btn
      >
    </v-card-title>
    <v-card-text
      v-if="!forbidden"
      class="ma-0 pa-0 pt-1"
      style="
        height:calc(100% - 67px); background-color:#F3F3F3;
      "
    >
      <v-card v-if="!timeSelected" flat color="transparent"
        ><SummaryCard
          :balance="balance"
          :totalUsed="totalUsed"
          :companyId="companyId"
          @forbiddenOpen="forbiddenOpen"/></v-card
      ><v-card v-if="timeSelected" flat color="transparent"
        ><TimeRangeCard
          :startDate="startDate"
          :endDate="endDate"
          :balance="balance"
          :totalUsed="totalUsed"
          :rangeUsed="rangeUsed"/></v-card
      ><v-card
        flat
        color="transparent"
        style="
        height:calc(100% - 190px);
      "
        ><BottomCard
          ref="bottomCard"
          :startDate="startDate"
          :endDate="endDate"
          :companyId="companyId"
          :timeSelected="timeSelected"
          @clearDate="clearDate"/></v-card></v-card-text
    ><v-card flat color="transparent" v-if="forbidden"><Forbidden403 /></v-card
  ></v-card>
</template>

<script>
import ApiConfig from '../../plugins/ApiConfig';
import TimeSelect from './TimeSelect';
import SummaryCard from './SummaryCard';
import TimeRangeCard from './TimeRangeCard';
import BottomCard from './BottomCard/Main';
import Forbidden403 from '../SystemPage/Forbidden403';
export default {
  components: {
    TimeSelect,
    SummaryCard,
    TimeRangeCard,
    BottomCard,
    Forbidden403,
  },
  props: {
    companyId: {
      type: String,
    },
  },
  data() {
    return {
      balance: null,
      totalUsed: null,
      rangeUsed: null,
      timeSearch: false,
      timeSelected: false,
      startDate: null,
      endDate: null,
      forbidden: false,
    };
  },
  watch: {
    companyId: {
      handler() {
        this.loadBalance();
        this.loadUsed();
      },
      deep: true,
    },
  },
  mounted() {
    this.loadBalance();
    this.loadUsed();
  },
  methods: {
    /**讀取資料 */
    async loadBalance() {
      var companyId = this.companyId;
      var start = null;
      var end = null;

      return new Promise((resolve) => {
        this.$API.api.pc.companyProcessingUsage
          .getBalance(companyId, start, end, ApiConfig.getJsonConfig)
          .then((res) => {
            this.balance = res.data;
          })
          .catch((error) => {
            console.error(error);
            if (error.response.status === 403) {
              this.forbidden = true;
            }
          })
          .finally(() => {
            resolve();
          });
      });
    },
    async loadUsed() {
      var companyId = this.companyId;
      var start = null;
      var end = null;
      var res = await this.$API.api.pc.companyProcessingUsage.getUsed(
        companyId,
        start,
        end,
        ApiConfig.getJsonConfig
      );
      this.totalUsed = -res.data;
    },
    async loadRangeUsed() {
      var start = this.startDate + 'T00:00:00+08:00';
      var end = this.endDate + 'T00:00:00+08:00';
      var res = await this.$API.api.pc.companyProcessingUsage.getUsed(
        this.companyId,
        start,
        end,
        ApiConfig.getJsonConfig
      );
      // console.log('res', res);
      this.rangeUsed = -res.data;
    },
    timeSelect(startDate, endDate) {
      this.timeSelected = true;
      this.startDate = startDate;
      this.endDate = endDate;
      this.loadRangeUsed();
    },
    timeSearchClear() {
      this.$refs.bottomCard.saveGridClear();
      this.timeSearch = false;
      this.timeSelected = false;
      this.rangeUsed = null;
      this.startDate = null;
      this.endDate = null;
    },
    clearDate() {
      this.startDate = null;
      this.endDate = null;
    },
    forbiddenOpen() {
      this.forbidden = true;
    },
  },
};
</script>
