<template>
  <v-card flat>
    <v-tabs v-model="tab" grow>
      <v-tab v-for="s in status" :key="s.id" @click="tabClick(s.id)">
        {{ s.name }}
      </v-tab>
    </v-tabs>
  </v-card>
</template>

<script>
export default {
  data() {
    return {
      tab: 0,
      status: [
        { name: '圖表', id: 1 },
        { name: '點數明細', id: 2 },
      ],
    };
  },
  methods: {
    tabClick(e) {
      this.$emit('tabClick', e);
    },
    fullScreenClose() {
      this.tab = 1;
    },
  },
};
</script>
