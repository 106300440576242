import { render, staticRenderFns } from "./TimeRangeCard.vue?vue&type=template&id=5b0654f7&scoped=true"
import script from "./TimeRangeCard.vue?vue&type=script&lang=js"
export * from "./TimeRangeCard.vue?vue&type=script&lang=js"
import style0 from "./TimeRangeCard.vue?vue&type=style&index=0&id=5b0654f7&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5b0654f7",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
installComponents(component, {VCard,VCardText,VCardTitle,VCol,VContainer,VRow,VSkeletonLoader})
